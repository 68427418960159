<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/aboutus/06-0banner.png"/>
        <div class="mobile-head-explain" style="margin-top:-30rem">
            <div class="mobile-head-explain-title">比孚信息科技</div>
            <div class="mobile-head-explain-content">比肩客户同行，深孚客户之望。<br/>成为客户首选的科技创新伙伴。</div>
        </div>
        <div class="mobile-head-catalogue">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>公司简介</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>愿景使命</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>资质荣誉</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==4} " @click="(checkIndex=4)"><span>合作伙伴</span></div>
        </div>
        <div v-if="(checkIndex==1)">
            <img class="service-img1" src="../../assets/image/aboutus/06-0小图.png"/>
            <div style="margin-top: 1.6rem;margin-bottom: 5rem;">
                <el-collapse-transition>
                    <div v-if="true">
                        <div class="description-box">上海比孚信息科技有限公司（以下简称“比孚”）是一家成立于2013年的专注于IT技术服务的高新技术企业。<br/><br/>
                        比孚核心团队成员来自多家国际知名咨询和信息技术服务公司，在toB信息化咨询领域有着丰富的管理和落地经验。<br/><br/>
                        随着数字化转型的深入以及IT技术的快速发展，企业的IT投入逐年升高，IT的ROI显得更加重要。面对不同行业不
                        同业务的业务和技术需求，比孚凭借积累多年的方法论和工具，始终围绕着价值和效率，为客户提供专业，快速见
                        效的解决方案。<br/><br/>
                        为向客户提供更具针对性的专业服务，比孚将技术服务整合为数据服务、SAP服务和技术开发服务三大服务板块，
                        提供从咨询设计到开发落地运维的完整技术服务。业务上，比孚聚焦于快消零售，制造以及医疗三大行业，不断研
                        发和打磨针对行业的解决方案和产品，帮助企业以更低成本更好效果更短时间内实现业务流程的数字化转型，提升
                        业务效率，减少人工，优化成本。</div>
                    </div>
                </el-collapse-transition>
            </div>
        </div>
        <div class="mobile-content-box1" v-if="(checkIndex==2)">
            <div class="custom-card2">
                <img src="../../assets/image/aboutus/06-0使命.svg">
                <div class="title-box">
                    <div class="card-title3-1">使命</div>
                    <div class="card-title3-2">推动企业数字化重塑和创新，<br/>助力企业成为数字化转型和创新的受益者</div> 
                </div>     
            </div>
            <div class="custom-card2">
                <img src="../../assets/image/aboutus/06-0愿景.svg">
                <div class="title-box">
                    <div class="card-title3-1">愿景</div>
                    <div class="card-title3-2">比肩客户同行，深孚客户之望。 <br/>成为客户首选的科技创新伙伴。</div> 
                </div>     
            </div>
            <div class="custom-card2" style="margin-bottom:5rem">
                <img src="../../assets/image/aboutus/06-0价值观.svg">
                <div class="title-box">
                    <div class="card-title3-1">价值观</div>
                    <div class="card-title3-2">诚信      担当<br/>  创新      共赢</div> 
                </div>     
            </div>
        </div>
        <div class="mobile-content-box1" v-if="(checkIndex==3)">
            <div class="aboutus-container">
                <img src="../../assets/image/aboutus/06-0资质1-1.png"/>
                <img src="../../assets/image/aboutus/06-0资质1-2.png"/>
                <img src="../../assets/image/aboutus/06-0资质1-3.png"/>
                <img src="../../assets/image/aboutus/06-0资质2-1.png"/>
                <img src="../../assets/image/aboutus/06-0资质2-2.png"/>
                <img src="../../assets/image/aboutus/06-0资质3.png"/>
                <img src="../../assets/image/aboutus/06-0资质4.png"/>
                <img src="../../assets/image/aboutus/06-0资质5.png"/>
                <img src="../../assets/image/aboutus/06-0资质6.png"/>
                <img src="../../assets/image/aboutus/06-0资质7.png"/>
                <img src="../../assets/image/aboutus/06-0资质8.png"/>
                <img src="../../assets/image/aboutus/06-0资质9.png"/>
                <img src="../../assets/image/aboutus/06-0资质10.png"/>
            </div>
        </div>
        <div class="mobile-content-box1" v-if="(checkIndex==4)">
            <div class="ware-title" style="margin-top:4.8rem;text-align: justify;">客户摘选</div>
            <img class="ware-img" style="margin-top:3rem;" src="../../assets/image/mobile/客户摘选.png"/>
            <div class="ware-title" style="margin-top:0rem;text-align: justify;">合作伙伴</div>
            <img class="ware-img" style="margin-top:3rem;" src="../../assets/image/mobile/合作伙伴.png"/>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            serviceIndex:1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            boxService:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-1需求调研.svg"),
                    title:'需求调研  原型设计',
                    content:'通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1蓝图设计.svg"),
                    title:'蓝图设计',
                    content:'蓝图设计包括功能设计,流程设计,权限设计，视觉设计，技术设计等。在需求关键点上持续沟通和确认。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'系统演示',
                    content:'在每个迭代开发结束后,通过操作演示,从平面上升到立体层面，与用户沟通和再次确认需求。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-1功能确认.svg"),
                    title:'最终功能确认',
                    content:'在这个阶,通常不再收集新的需求,重点在于查漏补缺。通过关键用户上手操作体验。对项目范围内所有功能进行最后一次确认和固化。针对提出的必要的优化建议,微调系统进行完善。',
                },
            ],
            boxService2:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-2运维效率.svg"),
                    title:'运维效率',
                    content:'通过有效地监控，应对各类突发故障。事前提前采取有效措施；事中提升响应速度；事后反馈，总结和优化，保障企业业务的稳定运行。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-2运维安全.svg"),
                    title:'运维安全',
                    content:'运维安全事件（系统宕机、数据异常、信息丢失等）可能给企业带来致命的打击，甚至关乎业务的生死存亡。因此，防范和杜绝高危运维故障是我们一直不懈追求的目标。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'业务持续性',
                    content:'客户业务发展瞬息万变，特别是重要业务，迭代变更的速度非常快。在Amazon云技术的支撑下，我们有能力持续高效地对应用进行发布、部署、变配等运维变更，保障业务的连续性和快速迭代。',
                },
            ],
            boxService3:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-4跟踪记录.svg"),
                    title:'跟踪记录',
                    content:'跟踪管理即对问题的整个生命周期进行记录和管理',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-4运维管理.svg"),
                    title:'运维管理',
                    content:'支持运维管理致捷或传统的开发项目。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-4协同合作.svg"),
                    title:'协同合作',
                    content:'支持各个团那队之间的协同合作,保证各个支持环节的透明性。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-4数据分析.svg"),
                    title:'数据分析',
                    content:'结合数据分析工具，做到对运维工作中的难点痛点了如指掌。',
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/technicalService/04-3-4管理面板.svg"),
                    title:'管理面板',
                    content:'提供管理面板，为持续不断优化管理运维工作捉供数据支撑。',
                },
            ],
            boxService4:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-1简单.svg"),
                    title:'简单',
                    content:'省却编程中大量重复机械代码，让开发更多关注业务逻辑，通过代码生成，结合手动调整，实现半智能开发',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1快速.svg"),
                    title:'快速',
                    content:'生成统一规范的界面及数据模型，可节省大量的工作量，快速提高开发效率',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1高效.svg"),
                    title:'高效',
                    content:'统一规范、统一设计思路，快速开发出高效高质量代码，缩短项目开发周期',
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 18.7rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.mobile-content-box2{
    width: 100%;
    display: flex;
    img{
        width: 6.7rem;
        height: 107.7rem;
        margin-top: 3rem;
    }
    .box2-right{
        margin-left: 3.7rem;
        .title1{
            height: 3.1rem;
            font-size: 2.8rem;
            font-family:'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.2rem;
        }
        .title2{
            max-width: 56.1rem;
            height: 9rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 4rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-img{
    width: 69.6rem;
    margin-left: -2rem;
    height: auto;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.service-img1{
    width: 69rem;
    height: 33.8rem;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    text-align: center;
}
.custom-card2{
    width: 100%;
    height: 22.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px 0px rgba(236,236,236,0.6);
    margin: auto;
    display: flex;
    margin-top: 2rem;

    img{
        width: 10rem;
        height: 10rem;
        margin: auto;
        // margin-left: 3rem;
    } 
    .title-box{
        padding-right: 8rem;
        .card-title3-1{
            height: 2rem;
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 3rem;
            text-align: justify;

            margin-top: 6rem;
        }
        .card-title3-2{
            width: 39.6rem;
            height: 7.5rem;
            font-size: 2.2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 4rem;
            text-align: justify;
            margin: auto;

            margin-top: 1.5rem;
        }
    }
}
.aboutus-container{
    width: 100%;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    img{
        width: 21.1rem;
        height: 28.7rem;
        margin-top: 4rem;
    }
}
</style>